.category-tabs {
    display: flex;
    width: 100%;
    background: #fdfdfd;
    box-sizing: border-box;
    justify-content: center;
}

.category-tab {
    height: 35px;
    border-width: 1px;
    border-style: solid;
    box-sizing: border-box;

    font-family: "Bebas Neue", sans-serif;
    font-weight: 700;
    letter-spacing: 1px;
    font-size: 13pt;

    cursor: pointer;
}

.category-tab.righttab {
    border-radius: 0px 10px 10px 0px;
    margin: 12px 8px 0px 0px;
}

.category-tab.lefttab {
    border-radius: 10px 0px 0px 10px;
    margin: 12px 0px 0px 8px;
}

.category-tab.active {
    background: #fdfdfd;
    border-color: #f0f0f0;
    color: black;
    transition: all 200ms cubic-bezier(0.15, 0.3, 0.25, 1);
}

.category-tab.inactive {
    background: #f0f0f0;
    border-color: #f0f0f0;
    color: grey;
    font-size: 11pt;
    transition: all 200ms cubic-bezier(0.15, 0.3, 0.25, 1);
}
